import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { Button, message, Result } from "antd";
import { QrcodeOutlined, SwapOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useScanSendingProduct } from "features/sending";
import { Loader, MobileModal } from "components";
import { useAuthStore } from "features/auth";
import {
  useSounds,
  RegistrarScannerForm,
  useScanner,
} from "features/qr-scanner";

export const Scanner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { commissionListId } = useParams();
  const isSending = Boolean(commissionListId);
  const isDevicePage = /\/devices\/\d/.test(location.pathname);

  const { playSuccess, playBip, playFail } = useSounds();
  const { isRight } = useAuthStore();
  const isRegistrar = isRight(["registrar"]);

  const isMobile = useMediaQuery({
    query: "(max-width: 639px)",
  });

  const {
    isOpen,
    closeScanner,
    disableScanning,
    isScannerDisabled,
    isRegistrarForm,
    isSendingConfirmation,
    showRegistrarForm,
    hideRegistrarForm,
    showSenderConfirmation,
    hideSenderConfirmation,
    enableScanning,
    toggleScanner,
  } = useScanner();

  const [facingMode, setFacingMode] = useState("user");
  const toggleCamera = () =>
    setFacingMode(facingMode === "user" ? "environment" : "user");

  const { scan, isScanning } = useScanSendingProduct({
    listNum: commissionListId,
    onSuccess: res => {
      enableScanning();
      if (res.message === "Product found in commission list") {
        showSenderConfirmation();
        playSuccess();
      } else {
        message.warning(res.message);
        playFail();
      }
    },
    onError: () => {
      playFail();
      enableScanning();
    },
  });

  useEffect(() => {
    if (isRegistrar && isDevicePage) {
      disableScanning();
      showRegistrarForm();
    }
  }, [location]);

  const onSuccessScan = (result, error) => {
    if (!!error || !result || isScannerDisabled()) return;
    if (!result.text.includes("/devices/")) return;

    if (isSending) {
      const split = result.text?.split("/");
      const deviceId = split[split.length - 1];
      scan(deviceId);
      disableScanning();
    } else if (isRegistrar) {
      navigate(`${result.text}?scanned=true`, { state: { isRegistrar } });
    } else {
      navigate(`${result.text}?scanned=true`);
      toggleScanner();
    }

    window.navigator.vibrate(200);
    playBip();
  };

  return (
    <>
      <Button
        className="mx-auto"
        style={{ scale: !isMobile ? "140%" : null }}
        size="large"
        type="ghost"
        icon={<QrcodeOutlined className="text-white" />}
        onClick={toggleScanner}
      />

      <MobileModal
        isOpen={isOpen}
        onCancel={toggleScanner}
        name="scanner"
        lockAspectRatio
      >
        <Loader />

        {facingMode === "user" && (
          <QrReader
            key="scanner-user"
            constraints={{ facingMode }}
            onResult={onSuccessScan}
          />
        )}

        {facingMode === "environment" && (
          <QrReader
            key="scanner-environment"
            constraints={{ facingMode }}
            onResult={onSuccessScan}
          />
        )}

        {isSendingConfirmation && (
          <div
            className="absolute inset-0 w-full flex justify-center
                  items-center bg-slate-100 z-10"
          >
            <Result
              status="success"
              title="Product was found!"
              extra={[
                <Button
                  key="ok"
                  size="large"
                  type="primary"
                  onClick={() => {
                    hideSenderConfirmation();
                    enableScanning();
                  }}
                >
                  ОК
                </Button>,
              ]}
            />
          </div>
        )}

        {isRegistrarForm && (
          <RegistrarScannerForm
            onCloseScanner={() => {
              closeScanner();
              enableScanning();
            }}
            onCancel={() => {
              hideRegistrarForm();
              enableScanning();
            }}
            onSuccess={() => {
              hideRegistrarForm();
              enableScanning();
            }}
          />
        )}

        <div className="flex flex-col items-center @[330px]:mt-2">
          <Button
            className="block mx-auto"
            type="ghost"
            icon={<SwapOutlined />}
            onClick={toggleCamera}
          />
          <span className="text-xs text-gray-300 hidden @[520px]:block">
            Swap Camera
          </span>
        </div>

        {isScanning && <Loader />}
      </MobileModal>
    </>
  );
};
