import { useState } from "react";
import {
  Button,
  message,
  App,
  Dropdown,
  Modal,
  Form,
  Select,
  Upload,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useSyncNewSKUs, useSyncWithEbay } from "features/sales";
import { useMarketplaceNames } from "features/marketplaces";

export const SyncPrice = () => {
  const { modal } = App.useApp();
  const [form] = Form.useForm();

  const [file, setFile] = useState([]);
  const [isNewSkuFormOpen, setIsNewSkuFormOpen] = useState(false);

  const openNewSkuForm = () => setIsNewSkuFormOpen(true);
  const closeNewSkuForm = () => {
    setIsNewSkuFormOpen(false);
    form.resetFields();
    setFile([]);
  };

  const { marketplaceOptions, isLoadingMarketplaces } = useMarketplaceNames();

  const { syncEbay, isSyncing } = useSyncWithEbay({
    onSuccess: () => {
      message.success({
        content:
          "Sync Price in progress. The result will be sent to your email.",
        duration: 5,
        onClick: () => message.destroy(),
      });
    },
  });

  const { syncNewSKUs, isSyncing: isSyncingNewSKUs } = useSyncNewSKUs({
    onSuccess: res => {
      message.success(res.message || "Success");
      closeNewSkuForm();
    },
  });

  const onClickEbay = () => {
    modal.confirm({
      title: "Sync Price",
      content: "Do you really want to run this procedure?",
      onOk: () => syncEbay(),
    });
  };

  const onSubmit = ({ marketplace }) => {
    const formData = new FormData();
    formData.append("marketplace", marketplace);
    formData.append("file", file[0]);
    syncNewSKUs(formData);
  };

  const items = [
    {
      onClick: onClickEbay,
      key: "sync-ebay",
      label: "eBay Price",
    },
    {
      onClick: openNewSkuForm,
      key: "sync-new-skus",
      label: "New SKUs",
    },
  ];

  return (
    <>
      <Dropdown
        trigger={["click"]}
        menu={{ items }}
        placement="bottom"
        disabled={isSyncing}
      >
        <Button className="text-xs sm:text-sm" icon={<SyncOutlined />}>
          Sync
        </Button>
      </Dropdown>

      <Modal
        title="Sync New SKUs"
        open={isNewSkuFormOpen}
        onCancel={closeNewSkuForm}
        onOk={form.submit}
        okText="Sync"
        okButtonProps={{ loading: isSyncingNewSKUs }}
      >
        <Form
          className="mt-5"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          disabled={isSyncingNewSKUs}
        >
          <Form.Item
            label="Marketplace"
            name="marketplace"
            rules={[
              { required: true, message: "Please select a marketplace!" },
            ]}
          >
            <Select
              options={marketplaceOptions}
              loading={isLoadingMarketplaces}
              placeholder="Select Marketplace"
            />
          </Form.Item>

          <Form.Item
            name="file"
            rules={[{ required: true, message: "Please upload a file!" }]}
          >
            <Upload
              withCredentials
              fileList={file}
              customRequest={({ file }) => {
                setFile([file]);
              }}
              onRemove={() => setFile([])}
            >
              {!file.length && <Button danger>Choose File</Button>}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
