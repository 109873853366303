import { Button, InputNumber, message, Space } from "antd";
import { useState } from "react";
import { useApplyFormulas } from "features/sales";
import { useCheckingFormulasProfit } from "features/profitability";
import {
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { AvailableFormulas } from "./AvailableFormulas";
import { MobileModal } from "components";

export const ApplyFormulas = ({ skuData }) => {
  const [price, setPrice] = useState(null);
  const [showFormulas, setShowFormulas] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { applyFormulas, isApplying } = useApplyFormulas({
    onSuccess: res => {
      message.success(res?.message);
      setShowConfirmation(false);
      setPrice(null);
    },
  });

  const onApply = () => {
    applyFormulas({
      ...skuData,
      price,
      original_box: Boolean(skuData.original_box),
    });
  };

  const { checkFormulasProfit, isChecking } = useCheckingFormulasProfit({
    onSuccess: ({ data }) => setShowConfirmation(data),
  });

  return (
    <>
      <Space.Compact className={"ml-auto mr-2"}>
        <Button
          onClick={() => setShowFormulas(true)}
          className={"text-gray-400"}
          icon={<QuestionCircleOutlined />}
        />
        <InputNumber
          min={0}
          value={price}
          onChange={setPrice}
          disabled={isApplying}
          placeholder={" Price"}
        />
        <Button
          onClick={() =>
            checkFormulasProfit({
              data: {
                price,
                ...skuData,
                original_box: Boolean(skuData.original_box),
              },
            })
          }
          disabled={!price}
          loading={isChecking || isApplying}
        >
          Apply Formulas
        </Button>
      </Space.Compact>

      <MobileModal
        isOpen={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        initialHeight={400}
        initialWidth={560}
        name="apply-formulas"
      >
        <div className="flex flex-col w-full h-full">
          <h3 className="mt-2 mb-5 flex justify-center gap-3">
            <ExclamationCircleFilled
              style={{ color: "#faad14", fontSize: 20 }}
            />
            Confirm the applied values
          </h3>

          <div className="mb-3 flex-1 overflow-auto">
            {Object.entries(showConfirmation).map(([k, v]) => (
              <div key={k} className="flex gap-6 my-1">
                <span className="w-1/3 text-gray-600 font-semibold">{k}</span>
                <span className="flex-1 font-mono text-green-800">{v}</span>
              </div>
            ))}
          </div>

          <footer className="flex justify-end gap-2">
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <Button type="primary" onClick={onApply} loading={isApplying}>
              Ok
            </Button>
          </footer>
        </div>
      </MobileModal>

      {showFormulas && (
        <AvailableFormulas
          onCancel={() => setShowFormulas(false)}
          skuData={skuData}
        />
      )}
    </>
  );
};
