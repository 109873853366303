import { useEffect } from "react";
import _ from "lodash";
import { Button, Form, Input, Alert, Card, App } from "antd";
import {
  useUpdateMarketplaceConfig,
  useSaveMarketplaceConfig,
  useMarketplaceConfigInfo,
  useRemoveMarketplaceConfig,
} from "features/marketplaces";
import { Loader } from "components";
import { requiredRule } from "constants/validation";
import { ExcludeSKUsField } from "features/directory";

const marketKey = "refurbed";

export const RefurbedConfig = () => {
  const { modal, message } = App.useApp();
  const [form] = Form.useForm();

  const { info, isLoadingInfo, isFetchingInfo } = useMarketplaceConfigInfo({
    marketKey,
  });

  const { saveConfig, isSaving } = useSaveMarketplaceConfig({
    marketKey,
    onSuccess: () => {},
  });

  const { updateConfig, isUpdating } = useUpdateMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  const { remove, isRemoving } = useRemoveMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  useEffect(() => {
    if (info) {
      form.setFieldValue("auth_token", info?.data.auth_token);
      form.setFieldValue("host", info?.data.host);
      form.setFieldValue(
        ["exclude_skus", "de"],
        info?.data.exclude_skus?.de || [],
      );
    }
    // eslint-disable-next-line
  }, [info]);

  const noConfig = _.isEmpty(info?.data);
  const anyProcessing =
    isSaving || isUpdating || isRemoving || isFetchingInfo || isLoadingInfo;

  return (
    <div className="flex flex-col max-w-lg w-full mx-auto relative pb-10">
      <h2 className="text-center">Refurbed</h2>

      {info?.data && noConfig && (
        <Alert
          className="mb-5 mt-6"
          type="error"
          message="First you need create config"
        />
      )}

      {info?.data?.is_connect && (
        <Alert
          className="mb-12 mt-8 "
          type="success"
          showIcon
          message="Connected"
        />
      )}

      {info?.data.is_connect === false && (
        <Alert
          className=" mb-5 mt-6"
          type="error"
          message="Need to update config"
        />
      )}

      <Card title="Config Settings">
        <Form
          form={form}
          requiredMark
          onFinish={noConfig ? saveConfig : updateConfig}
          disabled={anyProcessing}
        >
          <Form.Item label="Token" name="auth_token" rules={[requiredRule]}>
            <Input placeholder="Enter Auth Token" />
          </Form.Item>
          <Form.Item label="Host" name="host" rules={[requiredRule]}>
            <Input placeholder="Enter Host" />
          </Form.Item>

          <ExcludeSKUsField
            className="mb-2 mt-8"
            form={form}
            fieldName={["exclude_skus", "de"]}
            label="Set 9999 price for SKUs (Germany)"
            initialValue={info?.data.exclude_skus?.de}
            isLoading={isUpdating || isSaving}
          />

          <div className="flex justify-end gap-2">
            {noConfig ? (
              <Button type="primary" htmlType="submit" loading={isSaving}>
                Create
              </Button>
            ) : (
              <>
                <Button
                  danger
                  loading={isRemoving}
                  onClick={() => {
                    modal.confirm({
                      title: "Config Deleting",
                      content: "Are you sure you want to delete the config?",
                      onOk: () => remove(),
                    });
                  }}
                >
                  Delete
                </Button>

                <Button htmlType="submit" loading={isUpdating}>
                  Update
                </Button>
              </>
            )}
          </div>

          {(isLoadingInfo || isFetchingInfo) && <Loader />}
        </Form>
      </Card>
    </div>
  );
};
