import { ReloadOutlined } from "@ant-design/icons";
import { Resizable } from "react-resizable";
import { useState, useRef } from "react";
import Draggable from "react-draggable";
import { Button, Modal } from "antd";
import { useMobileModal } from "features/qr-scanner";
import { useMediaQuery } from "react-responsive";

export const MobileModal = ({
  children,
  isOpen,
  onCancel,
  name,
  lockAspectRatio,
  initialHeight = 526,
  initialWidth = 470,
  title = "Drag&Drop",
}) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 560px)",
  });

  const { size, position, setSize, setPosition } = useMobileModal({
    keyName: name,
    height: initialHeight,
    width: initialWidth,
  });

  const draggleRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const onDragStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!!targetRect)
      setBounds({
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      });
  };

  const onDrag = (_, { x, y }) => setPosition({ x, y });

  const onResize = (_, { size }) =>
    setSize({ width: size.width, height: size.height });

  const reset = () => {
    setSize({ width: initialWidth, height: initialHeight });
    setPosition({ x: 0, y: 0 });
  };

  if (isMobile)
    return (
      <Modal destroyOnClose open={isOpen} onCancel={onCancel} footer={null}>
        {children}
      </Modal>
    );

  return (
    <Modal
      destroyOnClose
      width={size.width + 48}
      open={isOpen}
      maskStyle={{ backgroundColor: "#00000021" }}
      onCancel={onCancel}
      footer={null}
      title={
        <header
          className="absolute w-full top-0 left-0 cursor-move text-gray-400 hover:bg-slate-100
              text-center py-2 font-thin text-xs rounded-t-lg z-20"
          onMouseOut={() => setDisabled(true)}
          onMouseOver={() => {
            if (disabled) setDisabled(false);
          }}
        >
          <Button
            type="ghost"
            size="small"
            shape="circle"
            className="absolute left-1 top-1"
            onClick={reset}
          >
            <ReloadOutlined style={{ color: "#c9c4c4" }} />
          </Button>
          {title}
        </header>
      }
      modalRender={modal => (
        <Draggable
          disabled={disabled}
          lockAspectRatio={true}
          bounds={bounds}
          nodeRef={draggleRef}
          onStart={onDragStart}
          onDrag={onDrag}
          position={position}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Resizable
        width={size.width}
        height={size.height}
        minConstraints={[480 / 2, 536 / 2]}
        onResize={onResize}
        lockAspectRatio={lockAspectRatio}
      >
        <div
          id="box"
          style={{ width: size.width, height: size.height }}
          className="@container"
        >
          {children}
        </div>
      </Resizable>
    </Modal>
  );
};
