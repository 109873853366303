import { create } from "lib/zustand-poxy";
import { create as _create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools, persist } from "zustand/middleware";

export const useMobileModal = ({
  keyName = "session-storage",
  height,
  width,
}) =>
  _create(
    persist(
      set => ({
        position: { x: 0, y: 0 },
        size: { width, height },
        setPosition: value => set(() => ({ position: value })),
        setSize: value => set(() => ({ size: value })),
      }),
      {
        name: keyName,
      },
    ),
  )();

export const useScanner = create(
  devtools(
    immer((set, get) => ({
      isOpen: false,
      locationCopy: null,
      isSendingConfirmation: false,
      isRegistrarForm: false,
      isDisabled: false,
      openScanner: () =>
        set(state => {
          state.isOpen = true;
        }),
      closeScanner: () =>
        set(state => {
          state.isOpen = false;
        }),
      toggleScanner: () =>
        set(state => {
          if (state.isOpen) {
            state.isDisabled = false;
            state.isRegistrarForm = false;
            state.isSendingConfirmation = false;
            state.isOpen = false;
          } else {
            state.isOpen = true;
          }
        }),
      setLocation: location =>
        set(state => {
          state.locationCopy = location;
        }),
      getLocation: () => get().locationCopy,
      disableScanning: () =>
        set(state => {
          state.isDisabled = true;
        }),
      enableScanning: () =>
        set(state => {
          state.isDisabled = false;
        }),
      isScannerDisabled: () => get().isDisabled,
      showRegistrarForm: () =>
        set(state => {
          state.isRegistrarForm = true;
        }),
      hideRegistrarForm: () =>
        set(state => {
          state.isRegistrarForm = false;
        }),
      showSenderConfirmation: () =>
        set(state => {
          state.isSendingConfirmation = true;
        }),
      hideSenderConfirmation: () =>
        set(state => {
          state.isSendingConfirmation = false;
        }),
    })),
  ),
);
