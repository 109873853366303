import { Radio } from "antd";

const filterName = "priority";

export const PriorityFilter = ({ store }) => {
  if (!store) return null;

  return (
    <Radio.Group
      className="whitespace-nowrap"
      onChange={e => {
        store.setFilter({ [filterName]: e.target.value });
      }}
      value={store.filter_by[filterName]}
    >
      <Radio value={undefined}>All</Radio>
      <Radio value={1}>Yes</Radio>
      <Radio value={0}>No</Radio>
    </Radio.Group>
  );
};
