import { CheckCircleOutlined, GiftFilled } from "@ant-design/icons";
import { Pagination, Switch, Table, Tooltip } from "antd";
import {
  SKULink,
  SKUsExport,
  SKUsFilter,
  SyncPrice,
  useOnSaleSKUs,
  useOnSaleSkuStore,
} from "features/sales";
import { SearchInput } from "components";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import _ from "lodash";

export const OnSaleSKUs = () => {
  const store = useOnSaleSkuStore();

  const [extendedView, setExtendedView] = useState(false);

  const {
    searchQuery,
    setSearchQuery,
    setPageSize,
    setPage,
    total,
    page,
    pageSize,
    setSorting,
    sorting,
  } = store;

  const { skus, isLoading, isFetching } = useOnSaleSKUs();

  const columns = useMemo(() => {
    return [
      {
        title: "SKU",
        key: "sort_by_sku",
        dataIndex: "sku_number",
        width: 100,
        align: "center",
        render: (_, item) => {
          const emulatedDevice = {
            grade: {
              id: item.grade_id,
              name: item.grade_name,
              sku_display: item.grade_sku_display,
            },
            original_box: item.original_box,
            sku: { sku_id: item.sku_id, sku_number: item.sku_number },
          };

          return <SKULink device={emulatedDevice} />;
        },
        sorter: true,
        sortOrder: sorting.sort_by_sku,
        showSorterTooltip: false,
      },
      {
        title: "Type",
        align: "center",
        key: "device_type_name",
        dataIndex: "device_type_name",
      },
      {
        title: "Device",
        align: "center",
        key: "device",
        dataIndex: "device",
        render: (_, { color_shade_name, device }) =>
          `${device?.replace(",", "")} ${color_shade_name}`,
        width: "30%",
      },
      {
        title: (
          <Tooltip title="Original Box">
            <GiftFilled />
          </Tooltip>
        ),
        key: "original_box",
        width: 60,
        align: "center",
        render: (_, { original_box }) =>
          original_box ? (
            <CheckCircleOutlined className="text-green-500" />
          ) : null,
      },
      {
        title: (
          <Tooltip title="Entered">
            <span className="text-xs">ENT</span>
          </Tooltip>
        ),
        key: "sort_by_entered",
        dataIndex: "entered_count",
        width: 60,
        align: "center",
        sorter: true,
        sortOrder: sorting.sort_by_entered,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Identified">
            <span className="text-xs">ID</span>
          </Tooltip>
        ),
        key: "sort_by_identified",
        dataIndex: "identified_count",
        align: "center",
        width: 60,
        sorter: true,
        sortOrder: sorting.sort_by_identified,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Graded">
            <span className="text-xs">GR</span>
          </Tooltip>
        ),
        key: "sort_by_graded",
        dataIndex: "graded_count",
        align: "center",
        width: 60,
        sorter: true,
        sortOrder: sorting.sort_by_graded,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Registered">
            <span className="text-xs">REG</span>
          </Tooltip>
        ),
        key: "sort_by_registered",
        dataIndex: "registered_count",
        align: "center",
        width: 60,
        sorter: true,
        sortOrder: sorting.sort_by_registered,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Hold">
            <span className="text-xs">H</span>
          </Tooltip>
        ),
        key: "sort_by_hold",
        dataIndex: "hold_count",
        align: "center",
        width: 60,
        sorter: true,
        sortOrder: sorting.sort_by_hold,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Repair">
            <span className="text-xs">REP</span>
          </Tooltip>
        ),
        key: "sort_by_repair",
        dataIndex: "repair_count",
        align: "center",
        width: 60,
        sorter: true,
        sortOrder: sorting.sort_by_repair,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Auction">
            <span className="text-xs">AU</span>
          </Tooltip>
        ),
        key: "sort_by_auction",
        dataIndex: "auction_count",
        align: "center",
        width: 60,
        sorter: true,
        sortOrder: sorting.sort_by_auction,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Market Return">
            <span className="text-xs">MR</span>
          </Tooltip>
        ),
        key: "sort_by_market_return",
        dataIndex: "market_return_count",
        align: "center",
        width: 60,
        sorter: true,
        sortOrder: sorting.sort_by_market_return,
        showSorterTooltip: false,
        hidden: !extendedView,
      },
      {
        title: (
          <Tooltip title="Ready for Sale">
            <span className="text-xs">
              {extendedView ? "RFS" : "Ready for Sale"}
            </span>
          </Tooltip>
        ),
        key: "sort_by_ready_for_sale",
        dataIndex: "ready_for_sale_count",
        align: "center",
        render: (count, sku) => {
          if (count === 0) return <span>{count}</span>;
          return (
            <Link
              to={`/sales?skuNumber=${sku.sku_number}&grade=${sku.grade_id}`}
              target="_blank"
            >
              {count}
            </Link>
          );
        },
        width: extendedView ? 60 : false,
        sorter: true,
        sortOrder: sorting.sort_by_ready_for_sale,
        showSorterTooltip: false,
      },
      {
        title: (
          <Tooltip title="On Sale">
            <span className="text-xs">{extendedView ? "ONS" : "On Sale"}</span>
          </Tooltip>
        ),
        key: "sort_by_on_sale",
        dataIndex: "on_sale_count",
        align: "center",
        width: extendedView ? 60 : false,
        sorter: true,
        sortOrder: sorting.sort_by_on_sale,
        showSorterTooltip: false,
      },
    ].filter(({ hidden }) => !hidden);
  }, [sorting, extendedView]);

  return (
    <div className="page-container flex-col">
      <h2 className="relative text-center">
        Sales Control by SKU
        <Tooltip title="Data view" className="absolute right-0">
          <Switch
            unCheckedChildren="Simple"
            checkedChildren="Extended"
            onChange={setExtendedView}
          />
        </Tooltip>
      </h2>

      <div className="flex justify-between mb-3 gap-2">
        <div className="mr-auto">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>

        <SyncPrice />
        <SKUsExport />

        <SKUsFilter
          fixedStatus
          store={store}
          isLoading={isLoading || isFetching}
          disabled={isLoading}
        />
      </div>

      <Table
        className="overflow-y-auto"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={skus?.data}
        sticky={true}
        rowKey={_.uniqueId}
        pagination={false}
        scroll={{ x: 1200 }}
        onChange={(pag, f, { columnKey, order }) => {
          setSorting(columnKey, order);
        }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={total}
        showTotal={false}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        disabled={isLoading || isFetching}
      />
    </div>
  );
};
