import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import TextArea from "antd/es/input/TextArea";
import { Button, Form, App, Select, Modal, Tooltip, Empty, Badge } from "antd";
import {
  PlusOutlined,
  ClearOutlined,
  UndoOutlined,
  CopyOutlined,
} from "@ant-design/icons";

export const ExcludeSKUsField = ({
  form,
  fieldName,
  initialValue = [],
  isLoading,
  className = "",
  label = "Excluded SKUs",
}) => {
  const { message } = App.useApp();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const actualFieldValue = Form.useWatch(fieldName, form) || [];

  const onCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(
        form.getFieldValue(fieldName).join(", "),
      );
      message.success("Copied!");
    } catch (e) {
      message.error("Failure! You probably need to set permissions.");
    }
  };

  const onDeleteSKUClick = e => {
    if (isLoading) return;
    e.preventDefault();
    form.setFieldValue(
      fieldName,
      _.without(actualFieldValue, e.target.dataset.key),
    );
  };

  const counter = <Badge count={actualFieldValue.length} size="small" />;

  return (
    <div className={className}>
      <header className="flex justify-between">
        <label className="flex items-center gap-1">
          {counter} {label}:
        </label>

        <div>
          <Tooltip title="Undo all changes">
            <Button
              type="text"
              disabled={_.isEqual(actualFieldValue, initialValue) || isLoading}
              onClick={() => form.setFieldValue(fieldName, initialValue)}
              size="small"
              icon={<UndoOutlined />}
            />
          </Tooltip>

          <Tooltip title="Copy all to clipboard">
            <Button
              type="text"
              size="small"
              disabled={!actualFieldValue?.length || isLoading}
              onClick={onCopyClick}
              icon={<CopyOutlined />}
            />
          </Tooltip>

          <Tooltip title="Clear all">
            <Button
              type="text"
              onClick={() => form.setFieldValue(fieldName, [])}
              size="small"
              disabled={!actualFieldValue?.length || isLoading}
              icon={<ClearOutlined />}
            />
          </Tooltip>

          <Tooltip title="Add SKUs">
            <Button
              type="text"
              onClick={openModal}
              size="small"
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </div>
      </header>

      <div
        className="border border-solid rounded-md border-slate-200 mb-4 p-1 min-h-[100px]
        max-h-[calc(100vh-600px)] overflow-y-auto"
      >
        {actualFieldValue?.map(sku => (
          <SKUButton
            key={sku}
            sku={sku}
            isLoading={isLoading}
            onClick={onDeleteSKUClick}
          />
        ))}

        {!actualFieldValue?.length && (
          <Empty
            className="mb-2 mt-4 cursor-copy"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            onClick={openModal}
          />
        )}
      </div>

      <Form.Item name={fieldName} hidden>
        <Select mode="multiple" />
      </Form.Item>

      {isModalOpen && (
        <AddingModal
          open={isModalOpen}
          onCancel={closeModal}
          hideReplaceBtn={!actualFieldValue?.length}
          onReplace={newValue => form.setFieldValue(fieldName, [...newValue])}
          onAdd={newValue => {
            const existingSKUs = form.getFieldValue(fieldName);
            form.setFieldValue(fieldName, _.union(existingSKUs, newValue));
          }}
        />
      )}
    </div>
  );
};

// CHIPS
const SKUButton = ({ sku, onClick, isLoading }) => {
  const mainStyle =
    "m-1 p-1 px-2 cursor-pointer border border-slate-300 rounded-md border-solid bg-sky-50 hover:bg-red-50 text-blue-900";
  const disabledStyle = "pointer-events-none cursor-not-allowed text-slate-400";

  return (
    <Tooltip title={sku} mouseEnterDelay={0.8}>
      <button
        className={clsx(mainStyle, { [disabledStyle]: isLoading })}
        data-key={sku}
        onClick={onClick}
      >
        {sku}
        <span className="text-gray-400 pointer-events-none ml-1">✕</span>
      </button>
    </Tooltip>
  );
};

// MODAL
const AddingModal = ({ open, onCancel, onAdd, onReplace, hideReplaceBtn }) => {
  const [addingForm] = Form.useForm();
  const addingValue = Form.useWatch("adding_skus", addingForm);

  const textAreaRef = useRef(null);

  const close = () => {
    onCancel();
    addingForm.resetFields();
  };

  useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  const getCleanValue = () => {
    const valueStr = addingForm.getFieldValue("adding_skus");
    return _.union(
      valueStr
        .replace(/[".,:;={}\[\]\/]/g, " ")
        .replace(/\s+/g, " ")
        .trim()
        .split(" "),
    );
  };

  const handleReplace = () => {
    const newSKUsArr = getCleanValue();
    onReplace(newSKUsArr);
    close();
  };

  const handleAdd = () => {
    const newSKUsArr = getCleanValue();
    onAdd(newSKUsArr);
    close();
  };

  return (
    <Modal title="Add SKUs" open={open} onCancel={close} footer={null} centered>
      <Form form={addingForm}>
        <Form.Item name="adding_skus">
          <TextArea autoSize={{ minRows: 2, maxRows: 10 }} ref={textAreaRef} />
        </Form.Item>

        <footer className="flex gap-2">
          <Button className="mr-auto" onClick={close}>
            Cancel
          </Button>

          {!hideReplaceBtn && (
            <Button
              disabled={!addingValue}
              danger
              type="primary"
              onClick={handleReplace}
            >
              Replace
            </Button>
          )}

          <Button type="primary" disabled={!addingValue} onClick={handleAdd}>
            Add
          </Button>
        </footer>
      </Form>
    </Modal>
  );
};
