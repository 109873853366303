import { useState } from "react";
import { Skeleton, Table } from "antd";
import { useParams, useNavigate, Link } from "react-router-dom";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { globalErrorHandler } from "lib/react-query-client";
import { CopyToClipboard, SearchInput } from "components";
import { PriorityFilter } from "features/filter";
import { useAuthStore } from "features/auth";
import {
  useInvoice,
  useInvoiceProducts,
  useInvoiceProductsStore,
  InvoicesPagination,
  GenerateQrCode,
  getProductsTableConfig,
  EditInvoice,
  InvoiceReimport,
} from "features/invoices";

export const Invoice = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const invoiceStore = useInvoiceProductsStore();

  const { isRight } = useAuthStore();
  const canGenerate = isRight(["admin", "registrar"]);
  const canEdit = isRight(["admin"]);

  const [editingInvoice, setEditingInvoice] = useState(null);
  const openEditModal = value => setEditingInvoice(value);
  const closeEditModal = () => setEditingInvoice(null);

  const { invoice, isLoadingInvoice } = useInvoice({
    id: invoiceId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const { products, isLoadingProducts, isFetching } =
    useInvoiceProducts(invoiceId);

  const isNoRetour = invoice?.data.return === false;

  const title = invoice ? (
    <h2 className="text-center">
      {`Invoice: #${invoice.data.invoice_number}`}{" "}
      <CopyToClipboard text={invoice.data.invoice_number} />
      {invoice?.data.return_list && (
        <div className="flex justify-center">
          <Link
            className="text-sm flex gap-2 items-center"
            to={`/invoices/manage/retour/${invoice.data.return_list.id}`}
          >
            {invoice.data.return_list.status === "MAPPING" ? (
              <LoadingOutlined />
            ) : invoice.data.return_list.status === "MAPPED" ? (
              <CheckCircleOutlined />
            ) : null}
            {invoice.data.return_list.status}
          </Link>
        </div>
      )}
    </h2>
  ) : (
    <h2 className="text-center">
      {`Invoice #${invoiceId}`} <CopyToClipboard text={invoiceId} />
    </h2>
  );

  return (
    <div className="page-container flex-col">
      {title}

      <div className="flex flex-col mr-auto mb-3 items-end gap-2 sm:flex-row">
        <ul className="m-0 mr-auto p-0 pl-1 list-none [&_li]:font-bold [&_span]:font-normal [&_span]:mr-2  [&_span]:text-gray-600">
          <li>
            <span>Invoice Number:</span>
            {isLoadingInvoice && <Skeleton.Input size="small" active />}
            {invoice && invoice.data.invoice_number}
          </li>
          <li>
            <span>Supplier Name:</span>
            {isLoadingInvoice && <Skeleton.Input size="small" active />}
            {invoice && invoice.data.supplier.supplier_name}
          </li>
          <li>
            <span>Grades:</span>

            {isLoadingInvoice && <Skeleton.Input size="small" active />}
            {invoice && invoice.data.grades.map(grade => grade.name).join(", ")}
          </li>
          <li>
            <span>Date:</span>
            {isLoadingInvoice && <Skeleton.Input size="small" active />}
            {invoice && invoice.data.invoice_date.split("T")[0]}
          </li>
          <hr />
          <li>
            <span>Total Quantity:</span>
            {isLoadingInvoice && <Skeleton.Input size="small" active />}
            {invoice && invoice.data.total_quantity}
          </li>
          <li>
            <span>Total Price in €:</span>
            {isLoadingInvoice && <Skeleton.Input size="small" active />}
            {invoice && invoice.data.total_price}
          </li>
        </ul>
      </div>

      <div className="mb-2 flex justify-between gap-2">
        <SearchInput
          className="min-w-[200px]"
          searchQuery={invoiceStore.search_term}
          setSearchQuery={invoiceStore.setSearchTerm}
        />

        <div className="mr-auto ml-2 flex items-center gap-3">
          <span>Priority:</span>
          <PriorityFilter store={invoiceStore} />
        </div>

        {canEdit && isNoRetour && (
          <InvoiceReimport invoiceId={invoiceId} disabled={!invoice} />
        )}

        {canGenerate && <GenerateQrCode invoice={invoice?.data} />}
      </div>

      <Table
        className="overflow-y-auto"
        loading={isLoadingProducts || isFetching}
        columns={getProductsTableConfig(openEditModal, canEdit)}
        dataSource={products?.data}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        scroll={{ x: 1200 }}
      />

      <InvoicesPagination
        controller={invoiceStore}
        disabled={isLoadingProducts || isFetching}
      />

      <EditInvoice
        isOpen={editingInvoice}
        initialInvoiceData={editingInvoice}
        onClose={closeEditModal}
      />
    </div>
  );
};
