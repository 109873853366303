import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Layout } from "antd";
import { useMediaQuery } from "react-responsive";
import { Logo, Header, NavigationMenu } from "components";
import { useAuthStore } from "features/auth/store";
import { LogOut, UserInfo, useScannerChannel } from "features/auth";
import { Scanner } from "features/qr-scanner";
const { Content } = Layout;

export const Root = () => {
  const { isLoggedIn, getUser, isRight } = useAuthStore();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 639px)" });

  useScannerChannel();

  if (!isLoggedIn) return <Navigate to="/login" />;

  if (
    pathname === "/" &&
    isRight([
      "admin",
      "sales_manager",
      "grader",
      "flasher",
      "packer",
      "head_of_flasher",
    ])
  )
    return <Navigate to="/dashboard" />;

  if (pathname === "/" && isRight(["prioritizer", "registrar"]))
    return <Navigate to="/invoices/manage" />;

  if (pathname === "/" && isRight(["sender"]))
    return <Navigate to="/sending/devices" />;

  if (pathname === "/") return <Navigate to="/devices" />;

  return (
    <Layout className="h-screen">
      <Header>
        <Logo
          width={isMobile ? "76" : "120"}
          height="auto"
          layout="responsive"
          clickable
        />
        <Scanner />
        <UserInfo userData={getUser()} />
        <LogOut />
      </Header>

      <Layout style={{ position: "relative" }}>
        <NavigationMenu />
        <Content className="overflow-y-auto">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
