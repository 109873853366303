import { Form, Tooltip } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { PaginatedSelect, SKU } from "components";
import { useInfiniteDevices } from "features/devices";

export const DeviceSelect = ({
  disabled,
  onClear,
  className,
  initialData,
  filter_by = null,
  label = "",
  name = "",
  rules = [],
  onChange,
}) => {
  const {
    products,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteDevices({ filter_by });

  const productOptions = products.map(getProductOption);

  const noInitialOption =
    initialData?.product_id &&
    !productOptions?.some(({ value }) => value === initialData?.product_id);

  const options = noInitialOption
    ? [getProductOption(initialData), ...productOptions]
    : productOptions;

  return (
    <Tooltip
      mouseEnterDelay={0.2}
      placement="topRight"
      overlayStyle={{ maxWidth: "90vw" }}
      title={initialData && disabled && getProductOption(initialData)?.label}
    >
      <Form.Item
        className={className}
        label={label}
        name={name}
        initialValue={initialData?.product_id || null}
        rules={rules}
      >
        <PaginatedSelect
          // popupMatchSelectWidth={false}
          dropdownStyle={{ minWidth: 860 }}
          disabled={disabled}
          searchValue={searchQuery}
          isLoading={isLoading}
          placeholder={disabled ? "" : "Select Product"}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onSearch={setSearchQuery}
          onChange={v =>
            onChange?.(
              v,
              options.find(({ value }) => value === v),
            )
          }
          onClear={() => {
            searchQuery ? setSearchQuery("") : onClear();
          }}
          options={options}
        />
      </Form.Item>
    </Tooltip>
  );
};

const getProductOption = product => {
  const { color, field_values, product_id, sku, grade, original_box } = product;

  const model =
    Object.values(field_values).find(
      field => field.device_field.device_field_name === "Model",
    )?.device_field.device_field_option[0]?.value || "";

  const memory =
    Object.values(field_values).find(
      field => field.device_field.device_field_name === "Memory",
    )?.device_field.device_field_option[0]?.value || "";

  const imei =
    Object.values(field_values).find(
      field => field.device_field.device_field_name === "IMEI",
    )?.device_field.device_field_value || "";

  let title = `${model} ${memory} ${color?.name || ""}`;

  const skuLabel = SKU.getLinkData({
    skuId: sku?.sku_id,
    skuNumber: sku?.sku_number,
    gradeId: sku?.grade_id,

    gradeName: grade?.name,
    gradeSkuDisplay: grade?.sku_display,
    originalBox: original_box,
  }).label;

  const isReturn = product.status === "MARKET_RETURN";

  const label = (
    <>
      <div className="whitespace-nowrap">{title}</div>

      <div className="flex gap-3 justify-center items-center text-slate-400 text-xs font-semibold">
        {isReturn && <RollbackOutlined className="text-blue-700 text-sm" />}
        <span>ID: {product_id}</span>
        <span>SKU: {skuLabel}</span>
        {imei && <span>IMEI: {imei}</span>}
      </div>
    </>
  );

  return {
    value: product_id,
    label,
    data: product,
  };
};
