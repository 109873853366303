import { useState } from "react";
import _ from "lodash";
import { useInfiniteQuery, useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { apiClient } from "lib/api-client";
import { globalErrorHandler } from "lib/react-query-client";
import { useSearchDevicesStore } from "features/devices";

const getDevices = ({
  search_term = null,
  per_page = 12,
  page = 1,
  filter_by = null,
  fixedFilter = {},
}) =>
  apiClient.get("api/product", {
    params: {
      per_page,
      page,
      search_term,
      filter_by: { ...filter_by, ...fixedFilter },
    },
  });

export const useDevices = ({ onSuccess, onError, fixedFilter = {} } = {}) => {
  const { page, setPage, pageSize, setTotal, searchQuery, filter_by } =
    useSearchDevicesStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: devices,
    isLoading,
    isFetching,
  } = useQuery(
    ["devices", page, pageSize, debouncedQuery, filter_by],
    () =>
      getDevices({
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        filter_by: _.isEmpty(filter_by) ? null : _.omit(filter_by, "color"),
        fixedFilter,
      }),
    {
      onSuccess: res => {
        const { per_page, current_page } = res.meta;
        const { next } = res.links;

        if (next) {
          setTotal(per_page * current_page + 1);
        } else if (!res.data.length) {
          setPage(1);
          setTotal(0);
        }

        onSuccess?.(res);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
    },
  );

  return { devices, isLoading, isFetching };
};

const infinite_getDevices = async ({
  pageParam,
  search_term,
  filter_by = null,
}) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term, filter_by },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteDevices = ({ onSuccess, filter_by } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-devices", filter_by, debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/product?per_page=12&page=1`,
      }) =>
        infinite_getDevices({
          pageParam,
          search_term: debouncedQuery || null,
          filter_by,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
        enabled: true,
      },
    );

  return {
    products: data?.pages?.map(({ data }) => data)?.flat() || [],
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
