import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";

const sync = data => {
  return apiClient.post("api/sku/marketplaces/connect-market-sku", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const useSyncNewSKUs = ({ onSuccess } = {}) => {
  const { mutate: syncNewSKUs, isLoading: isSyncing } = useMutation(
    data => sync(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );
  return { syncNewSKUs, isSyncing };
};
