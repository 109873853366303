// TODO: need refactoring
import { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Button,
  Modal,
  Form,
  Select,
  InputNumber,
  Input,
  Checkbox,
  message,
  Collapse,
} from "antd";
import {
  CaretRightOutlined,
  ToolFilled,
  FireFilled,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  useInfiniteColors,
  useInfiniteDeviceTypes,
  useInfiniteShades,
  useInfiniteSKUs,
} from "features/directory";
import {
  useGenerateQRCode,
  useInfiniteInvoiceProducts,
} from "features/invoices";
import { useMemo } from "react";
import { QRLabel } from "./QRLabel";
import { PaginatedSelect } from "components";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;
const { Panel } = Collapse;

export const GenerateQrCode = ({ disabled, invoice }) => {
  const initiated = useRef(false);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const closeForm = () => setIsFormOpen(false);
  const openForm = () => {
    setIsFormOpen(true);
    if (!initiated.current) {
      initiated.current = true;
    }
  };

  return (
    <>
      <Button
        type="primary"
        className="text-xs sm:text-sm"
        onClick={openForm}
        disabled={!invoice}
        icon={<QrcodeOutlined />}
      >
        Generate QR Code
      </Button>

      {initiated.current && (
        <GenerateQrCodeForm
          disabled={disabled}
          invoice={invoice}
          isOpen={isFormOpen}
          handleClose={closeForm}
        />
      )}
    </>
  );
};

const GenerateQrCodeForm = ({ disabled, invoice, isOpen, handleClose }) => {
  let componentRef = useRef();

  const [form] = Form.useForm();
  const [isQRGenerated, setIsQRGenerated] = useState(false);
  const [labelsData, setLabelsData] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef,
  });

  const color = Form.useWatch("color", form);
  const type = Form.useWatch("device_type_id", form);
  const prior = Form.useWatch("priority", form);
  const selectedGrade = Form.useWatch("grade_id", form);

  const isGradeA =
    invoice?.grades?.find(({ id }) => id === selectedGrade)?.name === "A";

  const {
    isLoading: isShadesLoading,
    shadeOptions: allShadesOptions,
    isFetchingNextPage: isFetchingNextShadesPage,
    fetchNextPage: fetchNextShadesPage,
    setSearchQuery: setShadesSearchQuery,
    searchQuery: shadesSearchQuery,
  } = useInfiniteShades();

  const {
    skuOptions,
    isFetchingNextPage: isFetchingNextSKUPage,
    isLoading: isLoadingSKUs,
    fetchNextPage: fetchNextSKUPage,
    setSearchQuery: setSKUSearchQuery,
    searchQuery: searchSKUQuery,
  } = useInfiniteSKUs({ typeId: type || null });

  const {
    deviceTypeOptions,
    devices,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteDeviceTypes();

  const {
    colorOptions,
    colors,
    isFetchingNextPage: isFetchingNextColors,
    isLoading: isColorsLoading,
    fetchNextPage: fetchNextColors,
    setSearchQuery: searchColor,
    searchQuery: searchingColor,
  } = useInfiniteColors();

  const {
    productOptions,
    isFetchingNextPage: isFetchingNextInvoiceProductsPage,
    isLoading: isLoadingInvoiceProducts,
    fetchNextPage: fetchNextInvoiceProductsPage,
    setSearchQuery,
    searchQuery,
    setInvoiceId: setInvoiceIdToShowProducts,
  } = useInfiniteInvoiceProducts();

  const shades = useMemo(() => {
    if (!colors || !color) return [];

    return colors
      .filter(item => item.id === color)[0]
      ?.shades?.map(shade => ({
        label: shade.name,
        value: shade.id,
      }));
  }, [color, colors]);

  const { generate, isGenerating } = useGenerateQRCode(device => {
    const isNeedPrint = device[0]?.device_type?.name !== "Accessory";
    handleClose();
    form.resetFields();
    message.success("Success!");
    device?.forEach(({ qr }) => console.log(qr));

    if (isNeedPrint) {
      setLabelsData(
        device?.map(item => ({
          id: item.product_id,
          url: item.qr,
          priority: item.priority,
        })),
      );
      setIsQRGenerated(true);
    }
  });

  useEffect(() => {
    if (isOpen) form.setFieldValue("count", 1);
    setIsQRGenerated(false);
  }, [isOpen]);

  useEffect(() => {
    if (isQRGenerated) handlePrint();
  }, [isQRGenerated]);

  const isAccessory =
    deviceTypeOptions.find(item => item.value === type)?.label === "Accessory";

  useEffect(() => {
    if (invoice?.id) setInvoiceIdToShowProducts(invoice.id);
  }, [invoice]);

  const onChangeSku = skuId => {
    const skuData = skuOptions?.find(({ value }) => value === skuId)?.data;

    form.setFieldValue("color_shade_id", skuData?.color_shade?.id);
    form.setFieldValue("color", skuData?.color_shade?.color_id);

    if (!!skuData) {
      Object.values(skuData?.field_values).forEach(field => {
        const isSelect =
          field.device_field.device_field_type.toUpperCase() === "SELECT";
        const isMultiSelect =
          field.device_field.device_field_type.toUpperCase() === "MULTISELECT";
        const isCheckbox =
          field.device_field.device_field_type.toUpperCase() === "CHECKBOX";

        let value;
        if (isSelect) {
          value = field.device_field.device_field_option[0]?.id;
        } else if (isMultiSelect) {
          value = field.device_field.device_field_option?.map(opt => opt?.id);
        } else if (isCheckbox) {
          value = field.device_field.device_field_value !== "false";
        } else {
          value = field.device_field.device_field_value;
        }

        form.setFieldValue(
          ["field_values", field.device_field.device_field_name],
          value,
        );
      });
    }
  };

  const onClearSku = () => {
    form.resetFields(["field_values", "sku_id", "color", "color_shade_id"]);
  };

  const onClose = () => {
    form.resetFields();
    handleClose();
  };

  const restFields = useMemo(() => {
    const result = devices
      ?.find(item => item.id === type)
      ?.fields?.filter(
        item =>
          item.name !== "Color" &&
          item.name !== "IMEI" &&
          item.name !== "Serial",
      );
    return result || [];
  }, [type, devices]);

  const finish = data => {
    if (!data.comment) delete data.comment;
    if (!data.sku_id) delete data.sku_id;

    const settedFormFieldsObject = data.field_values;
    const selectedTypeFieldsArray = devices.find(
      item => item.id === type,
    ).fields;

    const getTypeField = name =>
      selectedTypeFieldsArray.find(field => field.name === name);

    const field_values = [];
    if (data.field_values) {
      Object.entries(settedFormFieldsObject).forEach(
        ([formEntryKey, formEntryValue]) => {
          const typeField = getTypeField(formEntryKey);

          if (
            typeField.type.toUpperCase() === "TEXT" ||
            typeField.type.toUpperCase() === "CHECKBOX"
          ) {
            if (formEntryValue?.toString()) {
              field_values.push({
                device_field_id: typeField.id,
                value: formEntryValue?.toString(),
              });
            }
          } else if (typeField.type.toUpperCase() === "SELECT") {
            if (formEntryValue) {
              field_values.push({
                device_field_id: typeField.id,
                device_field_option_id: formEntryValue,
              });
            }
          } else {
            //MULTISELECT
            if (formEntryValue?.length !== 0) {
              formEntryValue?.forEach(opt => {
                field_values.push({
                  device_field_id: typeField.id,
                  device_field_option_id: opt,
                });
              });
            }
          }
        },
      );
    }

    if (data.priority) {
      data.priority = 1;
    } else {
      delete data.priority;
    }

    if (data.repair) data.status = "REPAIR";
    delete data.repair;

    if (data.additional_options) {
      data = {
        ...data,
        additional_options: Object.entries(data.additional_options)
          ?.filter(([key, value]) => !!value)
          .map(([key, value]) => ({ [key]: value })),
      };
    }
    generate({ ...data, field_values });
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="Generate QR Codes"
        width={860}
        centered
        wrapClassName="h-screen relative"
        style={{ top: 0, maxHeight: "100%" }}
        open={isOpen}
        confirmLoading={isGenerating}
        onCancel={({ target }) => {
          if (
            typeof target.className === "string" &&
            target.className?.includes("ant-modal-wrap")
          )
            return;
          if (!isGenerating) onClose();
        }}
        modalRender={modal => (
          <div className="max-h-screen pb-0 pt-6">{modal}</div>
        )}
        onOk={form.submit}
        okText="Generate"
        okButtonProps={{ disabled: isGenerating }}
      >
        <div className="overflow-y-auto pr-3" style={{ maxHeight: "76vh" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={finish}
            disabled={isGenerating}
          >
            <Form.Item hidden name="invoice_id" initialValue={invoice?.id}>
              <Input />
            </Form.Item>

            <Form.Item
              className="my-8"
              label="Device Type:"
              name="device_type_id"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <PaginatedSelect
                localSearch
                isLoading={isLoading}
                placeholder="Select Device Type"
                disabled={isGenerating}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onClear={() => form.setFieldValue("device_type_id", null)}
                options={deviceTypeOptions}
                filterOption={(searchValue, { children }) =>
                  children?.toLowerCase().includes(searchValue?.toLowerCase())
                }
                onChange={() => {
                  form.setFieldValue("sku_id", null);
                }}
              />
            </Form.Item>

            {/* {isAccessory && ( */}
            <Form.Item
              label="Connected Invoice Product"
              name="invoice_product_id"
            >
              <PaginatedSelect
                disabled={isGenerating}
                searchValue={searchQuery}
                isLoading={isLoadingInvoiceProducts}
                placeholder="Select Product"
                fetchNextPage={fetchNextInvoiceProductsPage}
                isFetchingNextPage={isFetchingNextInvoiceProductsPage}
                onSearch={setSearchQuery}
                onClear={() => form.setFieldValue("invoice_product_id", null)}
                options={productOptions}
              />
            </Form.Item>
            {/* )} */}

            <Form.Item
              className="my-8"
              label="Count:"
              name="count"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <InputNumber
                className="w-full"
                type="number"
                placeholder="Enter Number of QR Codes"
                min="1"
                max="100"
              />
            </Form.Item>

            <div className="flex gap-10">
              <Form.Item
                initialValue={false}
                className="my-7"
                name="priority"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={() => {
                    onClearSku();
                    form.setFields([
                      {
                        name: "grade_id",
                        errors: null,
                      },
                    ]);
                  }}
                >
                  <FireFilled className="text-gray-300 mr-0.5" />
                  Prior Device
                </Checkbox>
              </Form.Item>

              {invoice?.return && (
                <Form.Item
                  initialValue={false}
                  className="my-7"
                  name="repair"
                  valuePropName="checked"
                >
                  <Checkbox onChange={onClearSku}>
                    <ToolFilled className="text-gray-300 mr-0.5" />
                    Repair
                  </Checkbox>
                </Form.Item>
              )}
            </div>

            <>
              <Form.Item
                label={
                  <span>
                    SKU{" "}
                    {type ? (
                      ""
                    ) : (
                      <i className="text-xs text-gray-500">
                        (need device type)
                      </i>
                    )}
                  </span>
                }
                className="flex-1"
                name="sku_id"
                rules={prior || isAccessory ? [requiredRule] : null}
              >
                <PaginatedSelect
                  disabled={isGenerating || !type}
                  searchValue={searchSKUQuery}
                  isLoading={isLoadingSKUs}
                  placeholder="Select SKU"
                  fetchNextPage={fetchNextSKUPage}
                  isFetchingNextPage={isFetchingNextSKUPage}
                  onSearch={setSKUSearchQuery}
                  onClear={onClearSku}
                  options={skuOptions}
                  onChange={onChangeSku}
                />
              </Form.Item>

              <Form.Item
                label="Grade"
                className="flex-1"
                name="grade_id"
                rules={prior || isAccessory ? [requiredRule] : null}
              >
                <Select
                  allowClear
                  showArrow
                  showSearch
                  placeholder="Select Grade"
                  options={invoice?.grades?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  filterOption={(searchValue, { label }) =>
                    label?.toLowerCase().includes(searchValue?.toLowerCase())
                  }
                />
              </Form.Item>

              {isGradeA && (
                <Form.Item
                  hidden={isAccessory}
                  initialValue={isAccessory}
                  name={["additional_options", "Original Box"]}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled}>Original Box</Checkbox>
                </Form.Item>
              )}
            </>

            <Form.Item label="Comment" name="comment">
              <TextArea placeholder="Enter Comment" />
            </Form.Item>

            <Collapse
              className="mb-10 text-xs"
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                forceRender
                className="text-gray-500"
                header="Additional fields (depends on device type)"
                key="1"
              >
                {restFields.map(field => {
                  if (
                    field.type.toUpperCase() === "SELECT" ||
                    field.type.toUpperCase() === "MULTISELECT"
                  ) {
                    return (
                      <Form.Item
                        className="mb-7"
                        label={field.name}
                        name={["field_values", field.name]}
                        key={field.id}
                      >
                        <Select
                          disabled={prior}
                          showSearch
                          allowClear
                          showArrow
                          placeholder="Select some option"
                          mode={
                            field.type.toUpperCase() === "MULTISELECT"
                              ? "multiple"
                              : null
                          }
                          filterOption={(searchValue, { label }) =>
                            label
                              ?.toLowerCase()
                              .includes(searchValue?.toLowerCase())
                          }
                          options={field.options.map(opt => ({
                            label: opt.value,
                            value: opt.id,
                          }))}
                        />
                      </Form.Item>
                    );
                  } else if (field.type.toUpperCase() === "CHECKBOX") {
                    return (
                      <Form.Item
                        initialValue={false}
                        className="mb-7"
                        name={["field_values", field.name]}
                        key={field.id}
                        valuePropName="checked"
                      >
                        <Checkbox disabled={prior}>{field.name}</Checkbox>
                      </Form.Item>
                    );
                  } else {
                    return (
                      <Form.Item
                        className="mb-7"
                        label={field.name}
                        name={["field_values", field.name]}
                        key={field.id}
                      >
                        <Input disabled={prior} placeholder="Type here" />
                      </Form.Item>
                    );
                  }
                })}

                <div className="flex">
                  <Form.Item label="Color" className="flex-1" name="color">
                    <PaginatedSelect
                      localSearch
                      disabled={isGenerating || prior}
                      searchValue={searchingColor}
                      isLoading={isColorsLoading}
                      placeholder="Select Color"
                      fetchNextPage={fetchNextColors}
                      isFetchingNextPage={isFetchingNextColors}
                      onSearch={searchColor}
                      onClear={() => {
                        form.setFieldValue("color", null);
                        form.resetFields(["color_shade_id"]);
                      }}
                      options={colorOptions}
                      onChange={() => {
                        form.resetFields(["color_shade_id"]);
                      }}
                      filterOption={(searchValue, { children }) =>
                        children
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase())
                      }
                    />
                  </Form.Item>

                  <div className="flex items-center m-2">
                    <CaretRightOutlined className="text-slate-400" />
                  </div>

                  <Form.Item
                    className="flex-1"
                    label="Shade"
                    name="color_shade_id"
                  >
                    <PaginatedSelect
                      localSearch={!!color}
                      disabled={disabled || prior}
                      searchValue={shadesSearchQuery}
                      isLoading={isShadesLoading}
                      placeholder="Select Shade"
                      fetchNextPage={() => !color && fetchNextShadesPage()}
                      isFetchingNextPage={isFetchingNextShadesPage}
                      onSearch={setShadesSearchQuery}
                      options={!!color ? shades : allShadesOptions}
                      onClear={() => {
                        // form.setFieldValue("color", null);
                        form.resetFields(["color_shade_id"]);
                      }}
                      filterOption={(searchValue, { children }) =>
                        !!color
                          ? children
                              ?.toLowerCase()
                              .includes(searchValue?.toLowerCase())
                          : true
                      }
                    />
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          </Form>
        </div>
      </Modal>

      {isQRGenerated && !isAccessory && (
        <div style={{ display: "none" }}>
          <div className="m-0 p-0" ref={el => (componentRef = el)}>
            {labelsData.map(item => (
              <QRLabel
                priority={item.priority}
                key={item.id}
                url={item.url}
                id={item.id}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
